import React from "react";

function Summary() {
  return (
    <section id="summarySection" className="content-section">
      <div className="mobile-heading">
        <h1 className="text-xl text-white font-semibold">ABOUT</h1>
      </div>

      <p className="p-2">
        In 2013, I started computer programming in C as a holiday activity away
        from my school work. I gained experience through working on projects at
        a software consulting company, Pro Media Systems, with guidance from the
        senior colleagues. Besides my web dev commitments, I explored( and still
        do ) cyber-security engineering e.g. Malware dev, and 3D Animation as a
        character animator( though I am retired here🙂 ). Currently, I focus on
        backend technologies, primarily Nest.js & Express.js, while expanding my
        skills and learning GoLang & Rust for building high-speed backends. I
        also have a keen interest in the DevOps field and have experience in
        GHA, Jenkins and Docker for CI/CD pipelines. Recently, I am also getting
        into the world of AI, in particular, Machine Learning and Deep Learning
        with Python using Tensorflow, PyTorch and Keras, since this is the kind
        of work I do at my current job.
      </p>

      <p className="p-2">
        I program in TypeScript( <i>my primary langauge for web dev</i> ),
        JavaScript, Go, Python, and C/C++. I am also familiar with Rust.
      </p>

      <p className="p-2">
        Throughout my software engineering journey, I have worked on numerous
        web projects, extensively utilizing TypeScript, Node.js, Express.js,
        MongoDB( <i>for database work</i> ) and Nest.js, along with one project
        involving Python. I have 7 years of general programming experience.
        {/* with the last 4 concentrated on backend engineering for
        professional work. */}
      </p>

      <p className="p-2">
        During my period around computers, I have worked on a variety of
        projects with different requirements and technologies. I have worked on
        personal and freelance projects that required me to build REST APIs,
        GraphQL APIs, and microservices. These projects range from simple CRUD
        APIs to complex APIs that required me to integrate with third-party
        services such as Stripe, SendGrid, and Twilio. For example fin-tech,
        e-commerce, and instant messaging applications. I have also worked on
        projects that required me to build and deploy Docker containers to AWS
        EC2 instances and AWS Lambda functions. I am also a strong believer in
        the power of open-source software and community-driven projects. I have
        contributed to a few open-source that I'll list below in the projects
        section. I am also a big fan of the Linux operating system({" "}
        <i>Ubuntu & Kali</i> ) and use it as my primary OS for development work.
      </p>
      <p>
        The next section will be about some of my little experience in the
        software engineering field. I also put up a few projects that I have
        worked on and deployed in the past but redacted some since they're too
        toy-ish to be showcased( they were meant to lead me to the ones you will
        find here ). I hope you enjoy the next section though.🙂
      </p>
    </section>
  );
}

export default Summary;
